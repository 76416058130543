import Vue from "vue";

/**
 * Extract data from DB according to config
 * @returns {Promise} - Return a promise which will resolve when server replies
 */
export function extractData(config) {
  let promise = new Promise((resolve, reject) => {
    Vue.http
      .get(`/api/extract_data/`, { params: config })
      .then(resp => {
        resolve(resp);
      })
      .catch(error => {
        reject(error);
      });
  });
  return promise;
}

// timestamp interval
// a list of configuration tags, if empty -> all db configurations
// For each measurement, True or False -> if you'd like the value in output

// {
//   "date_from": "2022-02-22T21:32:30.590Z",
//   "date_to": "2022-02-22T21:32:30.590Z",
//   "subsample": False,
//   "tags": [
//     "string"
//   ],
//   "voc": true,
//   "co2": true,
//   "temperature": true,
//   "humidity": true
// }
